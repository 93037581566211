






















































import Component from 'vue-class-component'
import ExpandCollapse from "@/components/animations/ExpandCollapse.vue";
import UserPresentation from "@/components/UserPresentation.vue";
import SygniRoundedButton from "@/components/buttons/SygniRoundedButton.vue";
import {Statues} from "@/shared/interfaces/Statues";
import SygniArrowButton from "@/components/buttons/SygniArrowButton.vue";
import SygniInnerTable from "@/components/table/SygniInnerTable.vue";
import { Product} from "@/modules/genprox/modules/fund/modules/fundraising/store/types";

@Component({
  components: {SygniArrowButton, SygniRoundedButton, UserPresentation, ExpandCollapse }
})
export default class CapInnerTable extends SygniInnerTable<Product> {

  innerTableFields = [
    { key: 'name', sortable: false, label: 'Shareholders' },
    { key: 'founders.numberOfShares', sortable: false, label: '# OF SHARES' },
    { key: 'founders.value', class: 'hide', sortable: false, label: 'VALUE' },
    { key: 'investors.numberOfShares', sortable: false, borderless: false, label: '# OF SHARES' },
    { key: 'investors.value', class: 'hide', sortable: false, label: 'VALUE' },
    { key: 'options.numberOfShares', class: 'hide', sortable: false, label: '# OF SHARES' },
    { key: 'options.value', class: 'hide', sortable: false, label: 'VALUE' },
    { key: 'totals.numberOfShares', class: 'hide', sortable: false, label: '# OF SHARES' },
    { key: 'totals.value', sortable: false, label: 'VALUE' },
    { key: 'totalPercentage', sortable: false, label: 'PERCENT STRUCTURE' },
  ]

  get totals() {
    return this.$store.getters['capital/getTotals'];
  }

  statusClass(status: Statues) {
    switch(status) {
      case(Statues.active): {
        return 'primary';
      }
      case(Statues.invited): {
        return 'success';
      }
      case(Statues.awaiting): {
        return 'success';
      }
      case(Statues.pending): {
        return 'black';
      }
      case(Statues.rejected): {
        return 'danger';
      }
      case(Statues.declined): {
        return 'disabled';
      }
    }
    return '';
  }

  statusText(status: Statues): string {
    switch(status) {
      case(Statues.active): {
        return 'Active';
      }
      case(Statues.invited): {
        return 'Invited';
      }
      case(Statues.awaiting): {
        return 'Awaiting';
      }
      case(Statues.pending): {
        return 'Pending';
      }
      case(Statues.rejected): {
        return 'Rejected';
      }
      case(Statues.declined): {
        return 'Declined';
      }
    }
    return '';
  }

  getTotalPercentage(amount: number) {
    if (amount === undefined) amount = 0;
    const total = this.totals.totals.value ? this.totals.totals.value : 0;
    amount = Number(amount.toFixed(2)) * 100 / Number(total.toFixed(2));
    amount = Math.round((amount + Number.EPSILON) * 100) / 100;

    return `${this.$options.filters.numberFormat(amount, 2)}%`;
  }


  constructor() {  // PROTOTYPE COMPONENT
    super();
  }

  mounted() {
    // this.onMounted();
  }
}

