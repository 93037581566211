var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('b-table', _vm._g(_vm._b({
    staticClass: "cap-inner-table",
    attrs: {
      "innertable": "true",
      "fields": _vm.innerTableFields
    },
    scopedSlots: _vm._u([_vm._l(_vm.$scopedSlots, function (_, slot) {
      return {
        key: slot,
        fn: function fn(rowData) {
          return [_vm._t(slot, null, null, rowData)];
        }
      };
    }), {
      key: "cell(name)",
      fn: function fn(rowData) {
        var _rowData$item;

        return [_c('div', {
          class: ['cell-internal text-left', !((_rowData$item = rowData.item) !== null && _rowData$item !== void 0 && _rowData$item.name) ? 'min-h' : '']
        }, [_vm._v(_vm._s(rowData.item.name))])];
      }
    }, {
      key: "cell(founders.numberOfShares)",
      fn: function fn(rowData) {
        return [_c('div', {
          staticClass: "cell-internal text-right"
        }, [_vm._v(_vm._s(_vm._f("thousandSeparator")(rowData.item.founders.numberOfShares)))])];
      }
    }, {
      key: "cell(founders.value)",
      fn: function fn(rowData) {
        return [_c('div', {
          staticClass: "cell-internal text-right"
        }, [_vm._v(_vm._s(_vm._f("numberFormat")(rowData.item.founders.value, 2)))])];
      }
    }, {
      key: "cell(investors.numberOfShares)",
      fn: function fn(rowData) {
        return [_c('div', {
          staticClass: "cell-internal text-right"
        }, [_vm._v(_vm._s(_vm._f("thousandSeparator")(rowData.item.investors.numberOfShares)))])];
      }
    }, {
      key: "cell(investors.value)",
      fn: function fn(rowData) {
        return [_c('div', {
          staticClass: "cell-internal text-right"
        }, [_vm._v(_vm._s(_vm._f("numberFormat")(rowData.item.investors.value, 2)))])];
      }
    }, {
      key: "cell(options.numberOfShares)",
      fn: function fn(rowData) {
        return [_c('div', {
          staticClass: "cell-internal text-right"
        }, [_vm._v(_vm._s(_vm._f("thousandSeparator")(rowData.item.options.numberOfShares)))])];
      }
    }, {
      key: "cell(options.value)",
      fn: function fn(rowData) {
        return [_c('div', {
          staticClass: "cell-internal text-right"
        }, [_vm._v(_vm._s(_vm._f("numberFormat")(rowData.item.options.value, 2)))])];
      }
    }, {
      key: "cell(totals.numberOfShares)",
      fn: function fn(rowData) {
        return [_c('div', {
          staticClass: "cell-internal text-right"
        }, [_vm._v(_vm._s(_vm._f("thousandSeparator")(rowData.item.totals.numberOfShares)))])];
      }
    }, {
      key: "cell(totals.value)",
      fn: function fn(rowData) {
        return [_c('div', {
          staticClass: "cell-internal text-right"
        }, [_vm._v(_vm._s(_vm._f("numberFormat")(rowData.item.totals.value, 2)))])];
      }
    }, {
      key: "cell(totalPercentage)",
      fn: function fn(rowData) {
        return [_c('div', {
          staticClass: "cell-internal text-right"
        }, [_c('span', {
          staticClass: "text-nowrap"
        }, [_vm._v(_vm._s(_vm.getTotalPercentage(rowData.item.totals.value)))])])];
      }
    }], null, true)
  }, 'b-table', Object.assign({}, _vm.$props, _vm.$attrs), false), _vm.$listeners))], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }